<template>
	<v-app>
		<v-main class="ma-0 pa-0" :class="animateBackground ? 'fac-grad' : ''">
			<!-- ANIMATION -->
			<ul class="circles" v-if="animateBackground">
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
			</ul>

			<v-app-bar dark dense :src="imgBG" class="d-print-none">
				<template v-slot:img="{ props }">
					<v-img v-bind="props" gradient="to top, rgba(80,80,80,.6), rgba(20,20,20,.8)"></v-img>
				</template>

				<v-avatar tile size="36" class="mr-3"><img :src="imgLogo"></v-avatar>
				<v-toolbar-title @click="$router.push('/')" style="cursor: pointer;">FAC</v-toolbar-title>
				
				<v-spacer></v-spacer>

				<v-toolbar-title v-if="enableTimer">
					{{ timer }}
				</v-toolbar-title>

				<v-spacer></v-spacer>
				<!-- SIDEBAR MENU -->
				<v-menu offset-y>
      				<template v-slot:activator="{ on }">
						<v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
					</template>
					<v-card>
						<v-list class="py-0">
							<v-subheader>Dados</v-subheader>
							<v-list-item v-if="userData && userData.name && showAdmin">
								<v-chip small color="red" text-color="white">Administrador</v-chip>
							</v-list-item>
							<v-list-item v-if="userData && userData.name">
								<v-list-item-icon>
									<v-icon>mdi-account</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ '  '+`${userData.name} ${userData.surname}` }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
          					<v-list-item>
								<v-list-item-icon>
									<v-icon>mdi-email</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ userData && userData.email ? '  '+userData.email : '' }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item @click="resetPassword()">
								<v-list-item-icon>
									<v-icon>mdi-lock</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ '  Alterar Senha'}}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-divider></v-divider>
							<v-list-item @click="doLogout()" dense>
								<v-list-item-content>
									<v-list-item-title>Sair</v-list-item-title>
								</v-list-item-content>
								<v-list-item-icon>
									<v-icon>mdi-logout</v-icon>
								</v-list-item-icon>
							</v-list-item>
						</v-list>
					</v-card>
				</v-menu>

				<template v-slot:extension v-if="showAdmin">
					<v-tabs align-with-title>
						<v-tab :to="'/inicio'">Inicio</v-tab>
						<v-tab :to="'/admin/clientes'">Clientes</v-tab>
						<!--<v-tab :to="'/admin/produtos'">Produtos</v-tab>-->
					</v-tabs>
				</template>
			</v-app-bar>

			<!-- PAGES SECTION -->
			<v-container no-gutters fluid>
				<v-slide-y-transition mode="out-in">
					<router-view></router-view>
				</v-slide-y-transition>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import facLogo from '../assets/img/etctao.png'
import facImage from '../assets/img/fac-img2.jpg'

export default {
	name: 'Main',
	data() {
		return {
			userData: [],
			showAdmin: false,
			imgLogo: facLogo,
			imgBG: facImage,
			animateBackground: true,
			enableTimer: false,
			timer: ''
		}
	},
	mounted() {
		let productCode = sessionStorage.getItem('fac-product')
		this.$store.dispatch('checkAuth').then(result => {
			if (result && result.content && result.content.id) {
				this.userData = result.content
				if (this.userData && this.userData.id && this.userData.permission === 'ADMINISTRADOR') {
					this.showAdmin = true
				}

				// CHECK PRODUCT
				if (productCode) {
					if (this.$router.currentRoute && this.$router.currentRoute.path !== '/comprar') {
						this.$router.push('/comprar')
					}
				}
			}
		})

		this.$bus.$on('REFRESH_TIMER', (data) => {
			if (!this.$rules.isEmpty(data.minutes) && !this.$rules.isEmpty(data.seconds))
			this.enableTimer = true
			this.timer = data.minutes + ':' + data.seconds
		})
		this.$bus.$on('DISABLE_TIMER', () => {
			this.enableTimer = false
			this.timer = ''
		})
		this.$bus.$on('DISABLE_ANIMS', () => {
			this.animateBackground = false
		})
		this.$bus.$on('ENABLE_ANIMS', () => {
			this.animateBackground = true
		})
	},
	beforeDestroy() {
		this.$bus.$off('REFRESH_TIMER')
		this.$bus.$off('DISABLE_TIMER')
		this.$bus.$off('DISABLE_ANIMS')
		this.$bus.$off('ENABLE_ANIMS')
	},
	methods: {
		doLogout() {
			if (this.userData && this.userData.id) {
				this.$store.dispatch('doLogout', this.userData)
			}
		},
		resetPassword() {
			this.$router.push('/redefinicao')
		}
	}
}
</script>

<style scoped>
/* GRADIENT */
.fac-grad {
	/*background: linear-gradient(-45deg, #00662B, #00A645);*/
	background: linear-gradient(-45deg, #5a09db, #8c18da);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	/*width: 100%;
    height:100vh;*/
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* BOXES ANIMATION */
.area {
    background: #4e54c8;  
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    width: 100%;
    height:100vh;
}
.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate-circles 25s linear infinite;
    bottom: -150px;
    
}
.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}
.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}
.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}
.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}
.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}
.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}
.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}
.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}
.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}
.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate-circles {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}
</style>
